import { useSession } from "next-auth/react";
import * as Yup from "yup";
import React, { useState } from "react";
import eventClientApi from "@/api-client/event-client";
import Box from "@/components/ui/box";
import { Input } from "@/components/ui/input";
import SectionHeader from "@/components/ui/section-header";
import { Text } from "@/components/ui/text";
import { Button } from "@/components/ui/button";
import { ArrowLeft2, Camera } from "iconsax-react";
import { FileButton } from "@/components/ui/file-button";
import { uploadFile } from "@/lib/uploadthing";
import { EndPoints } from "@/types/uploadthing";
import { toast } from "sonner";
import { useFormik } from "formik";
import { DEFAULT_IMAGE_URL } from "@/lib/utils";
import { useModal } from "@/stores/use-modal-store";
import { Loader } from "@/components/ui/loader";

const CreateGroup = ({
  setCreateMode,
  selectedGroup,
  setShowPaticipantsBar,
  getAllGroups
}: any) => {
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { data: session }: any = useSession();

  const { onOpen } = useModal();

  const [initalValues, setInitalValues] = useState<any>(selectedGroup);

  const Schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
    image: Yup.string().nullable()
  });
  const formik = useFormik({
    initialValues: initalValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const payload = {
          ...values,
          image: values.image || DEFAULT_IMAGE_URL
        };
        const response = await eventClientApi
          .post(`api/v1/groups`, {
            json: payload
          })
          .json<any>();

        if (response.status === "success" || response.status === 201) {
          setCreateMode(false);
          setShowPaticipantsBar(true);
          getAllGroups();
          toast.success("Group created successfully");
        }
      } catch (err) {
        toast.success(err.message);
      } finally {
        setLoading(false);
      }
    }
  });

  const handleLogo = async (file: File | null) => {
    if (!file) return;
    setImageLoading(true);
    try {
      const { url } = await uploadFile(file as any, EndPoints.eventBanner);
      formik.setFieldValue("image", url, false);
    } catch (err) {
      toast.error("Something went wrong while uploading image.");
    } finally {
      setImageLoading(false);
    }
  };

  return (
    <div>
      <SectionHeader
        containerClassName="mt-s px-s"
        titleText={
          <Text
            variant={"title_three"}
            className="font-semibold transition-all duration-300 ease-in-out flex items-center"
          >
            <ArrowLeft2
              className="mr-m cursor-pointer"
              onClick={() => {
                setCreateMode(false);
                setShowPaticipantsBar(true);
              }}
            />
            Create Group
          </Text>
        }
        action={
          <div className="flex gap-m items-center">
            <Text variant={"caption_one"}></Text>
          </div>
        }
      />

      <Box className=" w-full ">
        <div className="mx-auto flex justify-center">
          <FileButton onChange={handleLogo} accept="image/png,image/jpeg" disabled={imageLoading}>
            {(props) => (
              <div className="relative group" {...props}>
                {formik.values.image ? (
                  <img
                    src={formik.values.image || DEFAULT_IMAGE_URL}
                    alt="user-profile"
                    className="rounded-full object-cover h-[160px] w-[160px]"
                  />
                ) : (
                  <img
                    src={DEFAULT_IMAGE_URL}
                    alt="user-profile"
                    className="rounded-full object-cover h-[160px] w-[160px]"
                  />
                )}
                {imageLoading ? (
                  <Loader
                    size="medium"
                    className="text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center"
                  />
                ) : (
                  <div className=" flex z-10 justify-center opacity-0 items-center rounded-full absolute top-0 bottom-0 backdrop-blur-10 h-[160px] w-[160px] bg-[#00000059] group-hover:opacity-100 transition-opacity duration-300">
                    <div className="flex flex-col z-10 justify-center items-center">
                      <div className="flex  flex-row mb-m-nudge">
                        <div
                          role="button"
                          className="shadow-subtle-sm  w-9 h-9 flex justify-center items-center  bg-container p-s-nudge rounded-small bottom-2 border-container-border backdrop-blur-0"
                        >
                          <Camera size={16}></Camera>
                        </div>
                      </div>
                      <div>
                        <Text className="text-body-2-strong text-[#ffffff] w-[116px] text-center">
                          Change Group Picture
                        </Text>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </FileButton>
        </div>
      </Box>

      <div className="px-m">
        <Text variant={"body_one"}>Name</Text>

        <Input
          variant="medium-s"
          className="rounded-4 w-full px-xl"
          inputClassName="w-full "
          placeholder="Name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          errorMessage={formik.errors.name}
          error={!!formik.errors.name}
        />

        <Text variant={"body_one"} className="mt-xl">
          Description
        </Text>

        <Input
          variant="medium-s"
          className="rounded-4 w-full px-xl"
          inputClassName="w-full "
          placeholder="Description"
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          errorMessage={formik.errors.description}
          error={!!formik.errors.description}
        />
      </div>

      <div className="flex justify-center gap-m p-l absolute left-0 bottom-0 right-0">
        <Button
          className="w-[180px]"
          variant="secondary"
          onClick={() => {
            setCreateMode(false);
            setShowPaticipantsBar(true);
          }}
        >
          Cancel
        </Button>
        <Button
          className="w-[180px]"
          variant="primary"
          loading={loading}
          onClick={() => formik.handleSubmit()}
        >
          {selectedGroup?.id ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default CreateGroup;
